import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

type Props = {
  isOpen: boolean;
  onClose: () => void;

  title: ReactNode;
  actions?: ReactNode;
};

export default function Modal(props: PropsWithChildren<Props>) {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={props.onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-slate-900 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block transform overflow-hidden rounded-lg bg-slate-800 py-6 px-4 text-left align-middle shadow-lg transition-all">
              <Dialog.Title
                as="h3"
                className="mr-24 text-lg font-medium leading-6 text-gray-100"
              >
                {props.title}
              </Dialog.Title>
              <div className="absolute top-0 right-0 p-6">
                <button onClick={props.onClose} className="h-4 w-4">
                  <XIcon className="text-white" />
                </button>
              </div>
              {props.children}

              {props.actions && <div className="mt-4">{props.actions}</div>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
