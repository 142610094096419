import { eosRPCEndpoint } from "../eos-store";
import { useRecoilState } from "recoil";
import { useMemo } from "react";
import Modal from "../components/Modal";
import classNames from "classnames";

export default function EndpointChooserDialog(props: {
  isOpen: boolean;
  kind: "rpc" | "aa" | undefined;
  onClose: () => void;
}) {
  const [rpcEndpoint, setRPCEndpoint] = useRecoilState(eosRPCEndpoint);

  const options = useMemo(
    () =>
      props.kind === "rpc"
        ? [
            "https://wax.pink.gg",
            "https://api.waxsweden.org",
            "https://wax.eu.eosamsterdam.net",
            "https://wax.greymass.com",
            "https://waxapi.ledgerwise.io",
            "https://wax.eosphere.io",
            "https://wax.eosdublin.io",
            "https://wax.blacklusion.io",
            "https://api-wax.eosarabia.net",
            "https://api-wax.eosauthority.com",
            "https://api.hivebp.io",
            "https://apiwax.3dkrender.com",
            "https://wax.eosdac.io",
            "https://wax-public.neftyblocks.com",
            "https://wax-public2.neftyblocks.com",
            "https://wax.blokcrafters.io",
            "https://wax.eosn.io",
            "https://api.wax.eosdetroit.io",
            "https://wax-bp.wizardsguild.one",
            "https://api.wax.alohaeos.com",
            "https://wax.cryptolions.io",
            "https://wax.dapplica.io",
            "https://wax.eoseoul.io",
          ]
        : props.kind === "aa"
        ? [
            "https://wax.api.atomicassets.io",
            "https://aa-api-wax.eosauthority.com",
            "https://api.wax.liquidstudios.io",
            "https://api-wax-aa.eosarabia.net",
            "https://atomic.3dkrender.com",
            "https://aa.wax.blacklusion.io",
            "https://atomic-wax-mainnet.wecan.dev",
            "https://wax-aa.eosdac.io",
            "https://wax-atomic-api.eosphere.io",
            "https://atomic.wax.eosrio.io",
            "https://api.atomic.greeneosio.com",
            "https://aa-wax-public1.neftyblocks.com",
            "https://atomicassets.ledgerwise.io",
            "https://atomic.tokengamer.io",
            "https://atomic.hivebp.io",
            "https://wax-atomic.eosiomadrid.io",
            "https://aa.dapplica.io",
          ]
        : [],
    [props.kind]
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Choose ${
        props.kind === "rpc"
          ? "RPC"
          : props.kind === "aa"
          ? "AtomicAssets"
          : "??"
      } Endpoint`}
      actions={
        <>
          <button
            type="button"
            className="text-primary inline-flex justify-center rounded-md border border-transparent bg-slate-900 px-4 py-2 text-sm font-medium hover:bg-slate-600 focus:outline-none
 focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2"
            onClick={props.onClose}
          >
            Confirm
          </button>
        </>
      }
    >
      <div className="mt-2 flex h-64 flex-col space-y-4 overflow-y-auto">
        {options.map((e) => (
          <button
            key={e}
            className={classNames(
              "rounded border-2 px-4 py-2 text-left text-white",
              {
                "border-green-400": e === rpcEndpoint,
                "border-gray-600": e !== rpcEndpoint,
              }
            )}
            onClick={() => (props.kind === "rpc" ? setRPCEndpoint(e) : void 0)}
          >
            <code>{e}</code>
          </button>
        ))}
      </div>
    </Modal>
  );
}
