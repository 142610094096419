import Button from "../../components/Button";
import { useShowSuccessMoment } from "../../success-moment";

export default function CPUMarketPage() {
  const showSuccessMoment = useShowSuccessMoment();
  return (
    <div className="flex flex-col justify-start space-y-2">
      <span>MARKET!</span>
      <div>
        <Button onClick={showSuccessMoment}>Play Success Moment</Button>
      </div>
    </div>
  );
}
