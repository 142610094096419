import { useShowSuccessMoment } from "../../../success-moment";
import Button from "../../../components/Button";
import { Suspense, SyntheticEvent, useCallback, useState } from "react";
import {
  atomicCustomMarketPlaceName,
  atomicMarketBalancesQuery,
  useBuyFunction,
} from "./store";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useRecoilState, useRecoilValue } from "recoil";
import { authQueries, useEosSignPushActions } from "../../../auth/store";

export default function AtomicCashbackPage() {
  const showSuccessMoment = useShowSuccessMoment();

  const [saleID, setSaleID] = useState("");
  const [taker, setTaker] = useRecoilState(atomicCustomMarketPlaceName);

  const [working, setWorking] = useState(false);

  const buyFn = useBuyFunction(saleID, taker);

  const buy = async (e: SyntheticEvent) => {
    e.preventDefault();
    setWorking(true);
    try {
      await buyFn(e);
      setSaleID("");
      showSuccessMoment();
    } catch (e) {
      toast.error(`Failed to buy: ${e}`);
    } finally {
      setWorking(false);
    }
  };

  return (
    <div className="flex flex-col justify-start space-y-2">
      <h1 className="text-xl">Cashback</h1>
      <Suspense fallback={<></>}>
        <WithdrawButtons />
      </Suspense>
      <form onSubmit={buy} className="flex max-w-xl flex-col space-y-2">
        <label className="flex flex-col">
          <span className="text-sm">Sale ID</span>
          <input
            type="text"
            className="rounded px-2 py-1 text-slate-900"
            value={saleID}
            onChange={(e) => setSaleID(e.target.value)}
          />
        </label>
        <label className="flex flex-col">
          <span className="text-sm">Taker Marketplace</span>
          <input
            type="text"
            className="rounded px-2 py-1 text-slate-900"
            value={taker}
            onChange={(e) => setTaker(e.target.value)}
          />
        </label>
        <div className="flex justify-start">
          <Button onClick={working ? undefined : buy}>
            {working ? <LoadingSpinner /> : "Buy"}
          </Button>
        </div>
      </form>
    </div>
  );
}

function WithdrawButtons() {
  const name = useRecoilValue(authQueries.activeUserName);
  const quantities = useRecoilValue(atomicMarketBalancesQuery(name));

  const { signPushActions } = useEosSignPushActions();
  const [working, setWorking] = useState(false);

  const withdraw = useCallback(
    async (q: string) => {
      try {
        setWorking(true);
        await signPushActions((perm) => ({
          account: "atomicmarket",
          name: "withdraw",
          authorization: [perm],
          data: {
            owner: name,
            token_to_withdraw: q,
          },
        }));
      } finally {
        setWorking(false);
      }
    },
    [signPushActions, setWorking, name]
  );

  return (
    <div className="flex space-x-2">
      {quantities.map((q) => (
        <Button onClick={working ? undefined : () => withdraw(q)}>
          Withdraw {q}
        </Button>
      ))}
    </div>
  );
}
