import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { Fragment, Suspense } from "react";
import { Transition } from "@headlessui/react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import LoadingSpinner from "../components/LoadingSpinner";

const _successMomentVisibleQuery = atom({
  key: "success-moment/_successMomentVisibleQuery",
  default: false,
});

const _successMomentAnimationData = selector({
  key: "success-moment/_successMomentAnimationData",
  get: async () => {
    return import("../animations/lottie-check.json");
  },
});

export function useShowSuccessMoment() {
  return useRecoilCallback(({ set }) => () => {
    set(_successMomentVisibleQuery, true);
  });
}

export function SuccessMomentRenderer() {
  const [visible, setVisible] = useRecoilState(_successMomentVisibleQuery);

  return (
    <Transition appear show={visible} as={Fragment}>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-900 bg-opacity-25" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block transform overflow-hidden transition-all">
              <Suspense fallback={<LoadingSpinner />}>
                {visible && <Render onEnded={() => setVisible(false)} />}
              </Suspense>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
}

function Render(props: { onEnded: () => void }) {
  const animationData = useRecoilValue(_successMomentAnimationData);
  return (
    <Lottie
      loop={false}
      animationData={animationData}
      play
      onComplete={() => setTimeout(() => props.onEnded(), 500)}
      style={{ width: 150, height: 150 }}
    />
  );
}
