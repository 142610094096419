import Button from "./components/Button";
import { useRecoilValue } from "recoil";
import {
  authQueries,
  useEosLoginTrigger,
  useEosLogoutTrigger,
} from "./auth/store";
import ErrorBoundary from "./ErrorBoundary";
import { Suspense, useState } from "react";
import { LoginIcon, LogoutIcon } from "@heroicons/react/outline";
import LoadingSpinner from "./components/LoadingSpinner";
import { Link, Route, Routes } from "react-router-dom";
import IndexPage from "./pages/index/IndexPage";
import EndpointChooserDialog from "./common/EndpointChooserDialog";
import CPUMarketPage from "./pages/cpu/CPUMarketPage";
import AtomicCashbackPage from "./pages/misc/cashback/AtomicCashbackPage";
import { SuccessMomentRenderer } from "./success-moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <Suspense fallback={<></>}>
      <div className="container mx-auto flex min-h-screen max-w-7xl flex-col space-y-4">
        <Nav />
        <div className="flex-1 px-4">
          <ErrorBoundary>
            <Suspense
              fallback={
                <div className="flex justify-center">
                  <LoadingSpinner />
                </div>
              }
            >
              <Routes>
                <Route path="/">
                  <Route index element={<IndexPage />} />
                  <Route path="/cpu/*" element={<CPUMarketPage />} />
                  <Route
                    path="/misc/cashback/*"
                    element={<AtomicCashbackPage />}
                  />
                </Route>
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </div>
        <Footer />
        <SuccessMomentRenderer />
        <ToastContainer />
      </div>
    </Suspense>
  );
}

function Nav() {
  return (
    <div className="w-full py-4 md:px-4">
      <div className="flex flex-row items-center justify-between bg-slate-800 px-6 py-4 md:rounded-xl">
        <div className="flex flex-row justify-start">
          <Link to="/" className="font-bold uppercase tracking-wide">
            IceCreamClub
          </Link>
        </div>
        <div className="flex flex-row justify-end">
          <ErrorBoundary>
            <LoginButton />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  const [chooserDialogFor, setChooserDialogFor] = useState<
    "rpc" | "aa" | undefined
  >(undefined);

  return (
    <footer className="pb-16 text-sm leading-6">
      <div className="mx-auto max-w-7xl divide-y divide-slate-700 px-4">
        <div className="flex pb-4">
          <span className="font-bold uppercase tracking-wide">
            IceCreamClub
          </span>
        </div>
        <div className="flex flex-row space-x-8 pt-4">
          <div>
            <h2 className="font-semibold text-slate-100">Socials</h2>
            <ul className="mt-3 space-y-2">
              <li>
                <a className="hover:text-slate-300" href="https://discord.com">
                  Discord
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="font-semibold text-slate-100">Settings</h2>
            <ul className="mt-3 space-y-2">
              <li>
                <button
                  className="hover:text-slate-300"
                  onClick={() => setChooserDialogFor("rpc")}
                >
                  Change RPC Endpoint
                </button>
              </li>
              <li>
                <button
                  className="hover:text-slate-300"
                  onClick={() => setChooserDialogFor("aa")}
                >
                  Change Atomic Endpoint
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EndpointChooserDialog
        isOpen={chooserDialogFor !== undefined}
        onClose={() => setChooserDialogFor(undefined)}
        kind={chooserDialogFor}
      />
    </footer>
  );
}

function LoginButton() {
  const login = useEosLoginTrigger();
  const logout = useEosLogoutTrigger();
  const name = useRecoilValue(authQueries.activeUserName);

  if (!name) {
    return (
      <Button onClick={login}>
        <span>Login</span>
        <LoginIcon className="h-4 w-4" />
      </Button>
    );
  }

  return (
    <Button onClick={logout}>
      <span>{name}</span>
      <LogoutIcon className="h-4 w-4" />
    </Button>
  );
}
