import { SyntheticEvent, useCallback } from "react";
import { useEosSignPushActions } from "../../../auth/store";
import { atom, selectorFamily, useRecoilValue } from "recoil";
import { eosRPC } from "../../../eos-store";
import { localStorageEffect } from "../../../common/store-effects";

export const atomicCustomMarketPlaceName = atom({
  key: "misc/cashback/atomicCustomMarketPlaceName",
  default: "efhrmarket24",
  effects: [localStorageEffect("r/misc/cashback/atomicCustomMarketPlaceName")],
});

export const atomicMarketBalancesQuery = selectorFamily({
  key: "misc/cashback/atomicMarketBalancesQuery",
  get:
    (name: string | undefined) =>
    async ({ get }) => {
      if (!name) {
        return [];
      }
      const rpc = get(eosRPC);

      const res = await rpc.get_table_rows({
        code: "atomicmarket",
        scope: "atomicmarket",
        table: "balances",
        lower_bound: name,
        upper_bound: name,
        json: true,
        limit: 1,
      });
      if (res.rows.length < 1) {
        return [];
      }
      return res.rows[0].quantities as Array<string>;
    },
});

export function useBuyFunction(saleID: string, takerMarketplace: string) {
  const { signPushActions } = useEosSignPushActions();
  const rpc = useRecoilValue(eosRPC);
  return useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      const res = await rpc.get_table_rows({
        code: "atomicmarket",
        scope: "atomicmarket",
        table: "sales",
        lower_bound: saleID,
        upper_bound: saleID,
        limit: 1,
        json: true,
      });

      if (res.rows.length !== 1) {
        throw new Error(`sale ${saleID} not found`);
      }

      const sale = res.rows[0] as {
        sale_id: number;
        asset_ids: Array<string>;
        listing_price: string;
        settlement_symbol: string;
      };

      await signPushActions((auth) => [
        {
          account: "atomicmarket",
          authorization: [auth],
          name: "assertsale",
          data: {
            asset_ids_to_assert: sale.asset_ids,
            listing_price_to_assert: sale.listing_price,
            sale_id: sale.sale_id,
            settlement_symbol_to_assert: sale.settlement_symbol,
          },
        },
        {
          account: "eosio.token",
          name: "transfer",
          authorization: [auth],
          data: {
            from: auth.actor,
            to: "atomicmarket",
            memo: "deposit",
            quantity: sale.listing_price,
          },
        },
        {
          account: "atomicmarket",
          authorization: [auth],
          name: "purchasesale",
          data: {
            buyer: auth.actor,
            intended_delphi_median: 0,
            sale_id: sale.sale_id,
            taker_marketplace: takerMarketplace,
          },
        },
      ]);
    },
    [saleID, takerMarketplace, rpc, signPushActions]
  );
}
