import Button from "../../components/Button";

export default function IndexPage() {
  return (
    <div className="px-4 md:px-6 lg:px-6">
      <div className="lg:text-center">
        <h2 className="text-base font-semibold uppercase tracking-wide text-amber-500">
          CPU Market
        </h2>
        <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-slate-200 sm:text-4xl">
          Democratizing CPU Lending on WAX
        </p>
        <p className="mt-4 max-w-3xl text-xl text-slate-300 lg:mx-auto">
          Lending WAX for other people's CPU was previously limited to big
          players with their own complicated infrastructure, which only makes
          sense with <span className="italic">huge</span> WAX holdings.
          <br />
          For users renting CPU prices are fixed and they have zero pricing
          power. Free markets solve both these problems.
        </p>
      </div>
      <div className="mt-16 flex lg:justify-center">
        <Button accent to="/cpu">
          Make your first order!
        </Button>
      </div>
    </div>
  );
}
