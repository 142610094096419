import { atom, selector } from "recoil";
import { localStorageEffect } from "./common/store-effects";
import { JsonRpc } from "eosjs";

export const eosRPCEndpoint = atom({
  key: "eosRPCEndpoint",
  default: "https://wax.eosphere.io",
  effects: [localStorageEffect("r/eosRPCEndpoint")],
});

export const eosRPC = selector({
  key: "eosRPC",
  get: ({ get }) => {
    return new JsonRpc(get(eosRPCEndpoint), { fetch: window.fetch });
  },
});
