import { atom, selector, useRecoilCallback } from "recoil";
import { Authenticator, User, UALError } from "universal-authenticator-library";
import { useCallback } from "react";
import { AnyAction, NameType } from "@greymass/eosio";
import { useUAL } from "./index";
import { toast } from "react-toastify";

type ActionMaker = (auth: {
  actor: NameType;
  permission: NameType;
}) => AnyAction | Array<AnyAction>;

export class SignTransactionError extends Error {}

export type UALCtx = {
  chains: Array<unknown>;
  authenticators: Array<Authenticator>;
  availableAuthenticators: Array<Authenticator>;
  appName: string;
  model: unknown;
  loading: boolean;
  users: Array<unknown>;
  activeAuthenticator: Authenticator | null;
  activeUser: User | null;
  isAutoLogin: boolean;
  error: UALError | null;
  message: string;
  hideModal: () => void;
  showModal: () => void;
  logout: () => void;
  restart: () => void;
  broadcastStatus: () => void;
  authenticateWithoutAccountInput: (
    authenticator: Authenticator,
    isAutoLogin?: boolean
  ) => Promise<void>;
  submitAccountForLogin: (
    accountInput: string,
    authenticator: Authenticator
  ) => Promise<void>;
};

export const internal = {
  activeUser: atom<User | null>({
    key: "auth/internal/activeUser",
    default: null,
    dangerouslyAllowMutability: true,
  }),
};

export function useEosLoginTrigger() {
  const ual = useUAL();
  return useCallback(() => {
    ual.showModal();
  }, [ual]);
}

export function useEosLogoutTrigger() {
  const ual = useUAL();
  return useCallback(() => {
    ual.logout();
  }, [ual]);
}

const nonce = atom({
  key: "_internal/auth/nonce",
  default: 1,
});

export const refreshOnTransaction = selector<unknown>({
  key: "auth/refreshOnTransaction",
  get: ({ get }) => get(nonce),
});

export function useEosRefresh() {
  return useRecoilCallback(({ set }) => () => {
    set(nonce, Date.now());
  });
}

export function useEosSignPushActions(opts?: { withFuel: boolean }) {
  const ual: any = useUAL();
  const signPushActions = useRecoilCallback(
    ({ snapshot, set }) =>
      async (actions: ActionMaker) => {
        if (!ual?.activeUser) {
          await ual.showModal();
          return;
        }

        const actor: string = await ual.activeUser.getAccountName();

        const accounts = ual.activeUser.scatter?.accounts;
        const permission =
          ual.activeUser.requestPermission ||
          (accounts?.length > 0 ? accounts[0].authority : "active");

        const pl = { actor, permission };

        try {
          const concrete = actions(pl);

          const realActions = Array.isArray(concrete) ? concrete : [concrete];

          const tx = { actions: realActions };
          const txHeader = { blocksBehind: 6, expireSeconds: 120 };

          await ual.activeUser.signTransaction(tx, txHeader);

          toast.info("Broadcasting transaction...");
          await new Promise((resolve) => setTimeout(resolve, 1500));
          set(nonce, Date.now());
          toast.success("Transaction executed locally");
        } catch (e: any) {
          console.error(e);
          toast.error(e.toString());
          throw new SignTransactionError(e);
        }
      },
    [ual, opts]
  );
  return {
    signPushActions,
  } as const;
}

export const authQueries = {
  activeUserName: selector({
    key: "auth/authQueries/activeUserName",
    get: async ({ get }) => {
      return get(internal.activeUser)?.getAccountName();
    },
  }),
};
